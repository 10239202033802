import { DefaultProps } from '../interface';
import Table from 'react-bootstrap/Table';
import { FunctionComponent } from 'react';

const TermSection: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const content = {
    th: [
      'ผู้เช่า/ผู้ยืมต้องมีอายุไม่น้อยกว่า 21 ปี และมีใบขับขี่สากลหรือใบอนุญาตขับขี่รถยนต์ตามที่หน่วยงานราชการออกให้ พร้อมนำเอกสารฉบับจริงมาแสดง ณ วันทำสัญญา ทั้งนี้ผู้เช่า/ผู้ยืมตกลงยินยอมให้ผู้ให้เช่า/ผู้ให้ยืมถ่ายรูปหรือถ่ายเอกสารบัตรประจำตัวประชาชนหรือหนังสือเดินทาง หรือใบอนุญาตขับขี่ไว้เป็นหลักฐาน',
      'ผู้เช่า/ผู้ยืมต้องใช้รถเพื่อการท่องเที่ยวหรือเพื่อประกอบธุรกิจที่ไม่ผิดกฎหมายเท่านั้น ผู้เช่า/ผู้ยืมตกลงจะไม่นำรถยนต์ที่เช่า/ยืมไปใช้ในเชิงพาณิชย์ หรือไปกระทำความผิด และไม่บรรทุกหรือมีสิ่งของผิดกฎหมายในรถ หรือกระทำการอื่นใดอันเป็นการฝ่าฝืนกฎหมายแห่งรัฐ หากมีการกระทำความผิดเกิดขึ้น ผู้เช่า/ผู้ยืมจะเป็นผู้รับผิดชอบแต่เพียงผู้เดียว',
      'ผู้เช่า/ผู้ยืมต้องใช้รถเฉพาะในพื้นที่ที่ได้แจ้งไว้กับผู้ให้เช่า/ผู้ให้ยืมตามที่ระบุไว้ในสัญญา หากผู้เช่า/ผู้ยืมฝ่าฝืนและผู้ให้เช่า/ผู้ให้ยืมไม่สามารถติดต่อผู้เช่า/ผู้ยืมได้ภายใน 2 ชั่วโมง ผู้ให้เช่า/ผู้ให้ยืมมีสิทธิแจ้งให้เจ้าหน้าที่ดำเนินการทางกฎหมายกับผู้เช่า/ผู้ยืมได้ทันที',
      'ในกรณีที่ผู้เช่า/ผู้ยืม กระทำนอกเหนือข้อตกลงดังข้อ 2-3. ทำให้ผู้ให้เช่า/ผู้ให้ยืมต้องนำรถกลับคืน ผู้เช่า/ผู้ยืมยินดีจะรับผิดชอบค่าใช้จ่ายในการนำรถกลับตามที่ผู้ให้เช่า/ผู้ให้ยืมขอเรียกเก็บ',
      'ผู้เช่า/ผู้ยืมตกลงไม่นำรถยนต์ที่เช่าไปให้เช่าช่วง โอน หรือส่งมอบให้บุคคลอื่นไปขับขี่อันขาด',
      'ผู้เช่า/ผู้ยืมตกลงว่าได้รับทราบข้อมูลรถยนต์ ประกันภัย จากผู้ให้เช่า/ผู้ให้ยืมหรือตัวแทนในรถยนต์ที่เช่าครบถ้วนแล้ว ณ วันที่รับรถ',
      'ผู้เช่า/ผู้ยืมต้องไม่ดื่มแอลกอฮอล์ขณะขับรถ หรือดื่มแอลกอฮอล์แล้วขับรถ หากมีความเสียหายใดๆเพราะเหตุดังกล่าวไม่ว่าจะโดยเจตนาหรือไม่ก็ตาม ผู้เช่า/ผู้ยืมต้องรับผิดชอบในความเสียหายทั้งหมด',
      'ในกรณีที่รถที่เช่าเกิดความเสียหายหรืออุบัติเหตุ ผู้เช่า/ผู้ยืมจะต้องแจ้งให้ผู้ให้เช่า/ผู้ให้ยืมทราบถึงเหตุทันที และ ผู้เช่า/ผู้ยืมตกลงที่จะจ่ายค่าปรับ/ค่าเสียหายส่วนแรก โดยอัตราค่าปรับ/ค่าเสียหายส่วนแรก นั้นให้เป็นไปตามแต่เหตุและกรณี ดังต่อไปนี้',
      'กรณีความเสียหายเกิดจากเหตุซึ่งมีผลเกี่ยวเนื่องกับการประกันภัย',
      'ก. กรณีอุบัติเหตุ หากผู้เช่า/ผู้ยืมเป็นฝ่ายถูก ผู้เช่า/ผู้ยืมไม่ต้องรับผิดชอบในความเสียหาย',
      <>
        ข. กรณีอุบัติเหตุ หากผู้เช่า/ผู้ยืมเป็นฝ่ายผิดหรือไม่มีคู่กรณี ผู้เช่า/ผู้ยืมจะต้องรับผิดในความเสียหาย
        (“ค่าเสียหายส่วนแรก”) ในอัตราระหว่าง 1,000 ถึง 50,000 บาท
        <b> เมื่อพิจารณาแล้วค่าเสียหายอยู่ในวงเงินที่บริษัทประกันภัยคุ้มครอง โดยอ้างอิงตามประเภทรถดังตารางต่อไปนี้</b>
      </>,
      <>
        ทั้งนี้อัตราค่าเสียหายส่วนแรกนั้นให้ถือการพิจารณาของทางผู้ให้เช่า/ผู้ให้ยืมนั้นเป็นที่สุด <br />
        นอกจากนี้ หากบริษัทประกันภัยประเมินราคาแล้ว ได้ความว่า ค่าเสียหายเกินกว่าวงเงินคุ้มครองที่กำหนดไว้
        ผู้เช่า/ผู้ยืมตกลงรับผิดชอบชดใช้เงินค่าใช้จ่ายส่วนเกินที่บริษัทประกันภัยไม่ได้คุ้มครอง
      </>,
      'กรณีความเสียหายอื่นๆ ให้เป็นไปตามอัตราค่าปรับตามหน้าสัญญา',
      'ผู้เช่า/ผู้ยืมต้องระมัดระวังมิให้รถเช่าหาย หากรถที่เช่าหายไม่ว่าด้วยเหตุใดๆ ผู้เช่า/ผู้ยืมจะต้องแสดงกุญแจตัวจริงให้ปรากฎแก่ผู้ให้เช่า/ผู้ให้ยืมเท่านั้น ผู้เช่า/ผู้ยืมมีหน้าที่ชำระค่าเสียหายเป็นจำนวนร้อยละ 20 ของราคารถยนต์ที่ประเมินไว้หรือส่วนต่างค่าใช้จ่ายรวมถึงส่วนเกินที่บริษัทประกันภัยไม่ได้คุ้มครอง เว้นแต่เป็นกรณีกุญแจตัวจริงและตัวรถหายด้วยกันทั้งหมด หรือคดีสิ้นสุดและมีหลักฐานควรเชื่อหรืออาจะเชื่อได้ว่าผู้เช่า/ผู้ยืมเป็นฝ่ายผิดในการทำให้รถหาย ซึ่งกรณีดังกล่าว ผู้เช่า/ผู้ยืมจะต้องชำระค่าใช้จ่ายของราคารถทั้งหมดร้อยละ 100 ของราคารถยนต์ที่ประเมินไว้',
      'ผู้เช่า/ผู้ยืมตกลงให้ผู้ให้เช่า/ผู้ให้ยืมตรวจสอบ หรือเคลื่อนย้ายรถ เมื่อผู้ให้เช่า/ผู้ให้ยืมเห็นควรให้ดำเนินการใดๆ เพื่อป้องกันความเสียหายต่อผู้ให้เช่า/ผู้ให้ยืมหรือรถยนต์คันที่ให้เช่า/ยืมได้ โดยไม่จำเป็นต้องบอกกล่าวล่วงหน้า',
      'หากอุปกรณ์ภายในรถยนต์ทั้งหมด เช่น เอกสารภายในรถยนต์ เบาะรถยนต์ ยาง อะไหล่ แม่แรง กุญแจและอื่นๆเป็นต้น ชำรุดหรือสูญหาย ในระหว่างการเช่ารถยนต์ ผู้เช่า/ผู้ยืมต้องจ่ายค่าปรับทั้งหมดตามรายการที่ผู้ให้เช่า/ผู้ให้ยืมเรียกเก็บ ผู้ให้เช่า/ผู้ให้ยืมจะใช้เกณฑ์ราคาค่าปรับตามราคาที่ศูนย์บริการรถยนต์ยี่ห้อนั้นๆกำหนดขึ้นและค่าบริการตามจริง',
      'ยางรถยนต์ หากยางรั่วผู้เช่า/ผู้ยืมต้องซ่อมปะยางมาคืนผู้ให้เช่า/ผู้ให้ยืม หากยางแตกผู้เช่า/ผู้ยืมจะต้องซื้อยางยี่ห้อ และลายตามเดิมให้ผู้ให้เช่า/ผู้ให้ยืมใหม่ ทั้งนี้ ผู้เช่า/ผู้ยืมจะต้องแจ้งให้ผู้ให้ยืมทราบถึงเหตุทันที หากเกิดเหตุการณ์ดังกล่าว',
      'ในกรณีที่รถยนต์ที่เช่า/ยืมนั้นถูกอายัด หรือกรณีถูกปรับจากเหตุอันเกิดจากการใช้รถของผู้เช่า/ผู้ยืม ทางผู้ให้เช่า/ผู้ให้ยืม มีสิทธิที่จะเรียกให้ผู้เช่า/ผู้ยืมชำระค่าปรับหรือค่าธรรมเนียมใดๆ จากเหตุดังกล่าวได้ตามจริง โดยหากผู้เช่า/ผู้ยืมไม่ชำระค่าปรับหรือค่าธรรมเนียมดังกล่าว ผู้ให้เช่า/ผู้ให้ยืมมีสิทธิหักเงินจำนวนดังกล่าวออกจากเงินประกันได้ทันทีโดยมิต้องได้รับความยินยอมและมีสิทธิในการเรียกร้องค่าชดเชยจากความเสียหายอื่นๆ (ถ้ามี) ได้ตามความเป็นจริง',
      <>
        <b>การนับเวลาในการเช่ารถ จะนับจากเวลาที่รับรถไปอีก 24 ชั่วโมง จึงนับเป็น 1 วัน</b>{' '}
        ผู้เช่า/ผู้ยืมต้องส่งคืนรถตรงเวลาตามที่ตกลง ณ สถานที่ที่ระบุไว้
        โดยหากไม่สามารถคืนรถได้ตามเวลาและ/หรือสถานที่ที่ระบุไว้
        ผู้เช่า/ผู้ยืมจะต้องแจ้งให้ผู้ให้เช่า/ผู้ให้ยืมทราบอย่างน้อย 3 ชั่วโมงก่อนถึงกำหนดคืนรถ{' '}
        <b>
          ซึ่งผู้ให้เช่า/ผู้ให้ยืมมีสิทธิปฏิเสธการขยายเวลาเช่า/ยืมดังกล่าวได้โดยมิถือว่าผิดสัญญา
          โดยในกรณีที่ผู้ให้เช่า/ผู้ให้ยืมตกลงที่จะให้มีการขยายเวลาการคืนรถ
          ผู้เช่า/ผู้ยืมตกลงที่จะชำระค่าบริการเพิ่มเติมดังต่อไปนี้
        </b>
      </>,
      'ยกตัวอย่าง : ค่าเช่าวันละ 1,000 บาท ค่าล่วงเวลา ชั่วโมงละ 100บาท',
      'สัญญาเช่า รับรถ วันที่ 5 มกราคม เวลา 10:00น. คืนรถ วันที่ 5 มกราคม เวลา 18:00น. ราคาค่าเช่าเก็บเต็มจำนวนเช่า 1 วัน คือ 1,000 บาท สามารถคืนรถ วันที่ 6 มกราคม 10:00 น. โดยไม่มีค่าใช้จ่าย',
      'สัญญาเช่า รับรถ วันที่ 5 มกราคม เวลา 10:00น. คืนรถ วันที่ 6 มกราคม เวลา 12:00น. ราคาค่าเช่าตามสัญญา 1 วัน และ 2 ชั่วโมง คือ 1,200 บาท ผู้เช่า/ผู้ยืมต้องการคืนรถล่าช้าไปอีก 2 ชั่วโมง คือ วันที่ 6 มกราคม 14:00 น. จะมีค่าใช้จ่ายเพิ่ม 200 บาท โดยค่าใช้จ่ายรวมเป็น ราคาเช่าราคาเต็ม 1 วัน และ 4 ชั่วโมง คือ 1,400 บาท ในทางกลับกัน หากต้องการคืนรถล่าช้า ไปเวลาอีก 3 ชั่วโมง คือ วันที่ 6 มกราคม 15:00 น. จะมีค่าใช้จ่ายรวมเป็น ราคาเช่าเต็ม 2 วัน คือ 2,000 บาท',
      '*การเปลี่ยนแปลงการเช่าทุกกรณี ต้องแจ้งผู้ให้เช่า/ผู้ให้ยืมทราบ และตกลงยินยอมก่อน',
      'ผู้ให้เช่า/ผู้ให้ยืมไม่ต้องรับผิดชอบใดๆ ในทรัพย์สินที่ผู้เช่า/ผู้ยืมลืมไว้ในรถที่เช่าทั้งสิ้น',
      'ผู้เช่า/ผู้ยืมต้องเติมน้ำมันให้เต็มตามที่กำหนดก่อนส่งคืน ไม่เช่นนั้นผู้ให้เช่า/ผู้ให้ยืมสามารถเรียกเก็บเงินค่าน้ำมัน หรือยินยอมให้หักค่าน้ำมันที่ขาดจากเงินประกันได้ทันที โดยหากผู้เช่า/ผู้ยืมเติมน้ำมันไม่ตรงตามชนิดที่กำหนดไว้ ผู้เช่า/ผู้ยืมยินยอมที่จะชดใช้ค่าเสียหายที่เกิดขึ้นทั้งหมด',
      'ภายใต้สัญญานี้ ผู้ให้เช่า/ผู้ให้ยืมต้องคืนเงินประกันให้ผู้เช่า/ผู้ยืมเต็มจำนวนเมื่อผู้เช่า/ผู้ยืมส่งมอบรถคืน โดยที่ผู้เช่า/ผู้ยืมไม่ได้กระทำผิดสัญญา และไม่ปรากฎความเสียหายอย่างใดๆ ในทางกลับกัน หากผู้เช่า/ผู้ยืมกระทำผิดสัญญาข้อหนึ่งข้อใดดังกล่าวข้างต้น ผู้เช่า/ผู้ยืมยินยอมให้ทางผู้ให้เช่า/ผู้ให้ยืมริบเงินประกันได้ทันทีและยินยอมให้ฟ้องร้องชดใช้ค่าเสียหายได้ตามจริง โดยหากผู้เช่า/ผู้ยืมไม่ชำระค่าปรับในกรณีที่ผู้ให้เช่า/ผู้ให้ยืมสามารถใช้สิทธิปรับได้ ผู้ให้เช่า/ผู้ให้ยืมมีสิทธิหักค่าปรับดังกล่าวออกจากเงินประกันได้ทันที',
      'สัญญาเช่าฉบับนี้มีผลบังคับเฉพาะวันที่เช่า/ยืมตามที่กำหนดไว้ในสัญญาเท่านั้น หรือรวมถึงสัญญาที่มีการเช่าต่อ หากผู้เช่า/ผู้ยืม ไม่สามารถคืนรถได้ตามกำหนดเวลา และไม่แจ้งให้ผู้ให้เช่า/ผู้ให้ยืมทราบโดยทันที หากเกิดกำหนดเวลาคืนรถเกินกว่า 3 ชั่วโมง หรือไม่สามารถติดต่อตัวผู้เช่า/ผู้ยืมรถยนต์ได้ไม่ว่าด้วยประการใดๆ ผู้ให้เช่า/ผู้ให้ยืม/ผู้ถือกรรมสิทธิ์/ผู้ครอบครองรถสามารถแจ้งความต่อเจ้าหน้าที่ตำรวจเพื่อดำเนินคดีอาญาในข้อหาลักทรัพย์โดยใช้กลอุบายหรือยักยอกทรัพย์ได้ทันที หากมีค่าเสียหายหรือค่าดำเนินการใดๆ เกิดขึ้นจากการนี้ ผู้เช่า/ผู้ยืมตกลงเป็นผู้รับผิดชอบโดยไม่มีข้อโต้แย้งใดๆ ทั้งสิ้น',
      'หากผู้เช่า/ผู้ยืม ผิดสัญญาข้อหนึ่งข้อใดดังกล่าวข้างต้น ผู้เช่า/ผู้ยืมตกลงยินยอมให้ผู้ให้เช่า/ผู้ให้ยืมริบเงินประกันที่วางทั้งหมดได้ทันทีเพื่อบรรเทาความเสียหายเบื้องต้น อย่างไรก็ดี ในกรณีที่ค่าความเสียหายเกินกว่าเงินประกัน ผู้ให้เช่า/ผู้ให้ยืมมีสิทธิเรียกร้องค่าเสียหายหรือนำคดีขึ้นสู่ศาลเพื่อเรียกร้องความเสียหายได้ตามความจริง',
      'ไดรฟ์ฮัพเป็นตัวกลางจัดหาบริการรถเช่าเท่านั้น ไม่มีส่วนรับผิดชอบในความเสียหาย หรือการกระทำๆ อันเกิดจากผู้ให้เช่า/ผู้ให้ยืม และ/หรือ ผู้เช่า/ผู้ยืม หากผู้เช่า/ผู้ให้ยืมผิดสัญญา ข้อหนึ่งข้อใด และไม่ยอมชดใช้ค่าเสียหายใดๆ ไดรฟ์ฮัพไม่มีสถานะเป็นลูกจ้าง ผู้ค้ำประกัน ลูกหนี้ร่วม หรือต้องร่วมรับผิดชอบใดๆในเอกสารฉบับนี้ และเป็นที่เข้าใจด้วยว่าหากสัญญาเช่านี้มีผู้คำประกัน ผู้ค้ำประกันได้รับทราบเงื่อนไขสัญญานี้ตลอดดีแล้ว ผู้ค้ำประกันจึงมีความผูกพันรับผิดชอบชดใช้และใช้ค่าเสียหายที่เกิดขึ้นทุกประการ'
    ],
    en: [
      'The lessee/borrower shall be not less than 21 years of age, shall possess international driving permit or driving permit issued by a government authority, and present the original documents on the date of signing the agreement. However, the lessor/lender agrees and allows the lessee/borrower to take photographs or make a copy of the personal identification card, passport or driving permit as evidence thereof.',
      'The lessee/borrower shall use the car for travel or legal business activities only. In addition, the lessee/borrower agrees not to use the rented/borrowed car for commercial purposes or offense, and agrees not to carry or have any illegal goods in the car or perform any actions which are deemed to be in a violation of national laws. In case an offense occurs, the lessee/borrower shall be solely responsible for such matter.',
      'The lessee/borrower shall use the car in the area informed to the lessor/lender only as specified in the agreement. If the lessee/borrower is in breach of the agreement and the lessor/lender cannot contact the lessee/borrower within 2 hours, the lessor/lender shall be entitled to inform its staff to take legal action against the lessee/borrower with immediate effect.',
      'In the event that the lessee/borrower performs any actions other than specified in the agreement under Clauses 2-3, which requires the lessor/lender to have the rented car returned, the lessee/borrower agrees to be responsible for the expenses arising from returning the car according to the amount charged by the lessor/lender.',
      'The lessee/borrower strictly agrees not to sublease, transfer or hand over the rented car to any third party to drive.',
      'The lessee/borrower agrees that it completely acknowledges the details regarding the car and insurance of the rented car from the lessor/lender or its representative as of the date of receiving the car.',
      'The lessee/borrower shall not consume any alcohol while driving, or consume any alcohol and then drive. If there are any damages as a result, whether intentional or not, the lessee/borrower shall be responsible for all damages.',
      'In the event that the rented car is damaged or there is an accident, the lessee/borrower shall inform the lessor/lender immediately, and the lessee/borrower agrees to pay the fine/excess according to the cause and events as follows:',
      'In the event that damages arise from an accident which affects the insurance.',
      'A. If the lessee/borrower is a no-fault party in an accident, the lessee/borrower shall not be responsible for the damages.',
      <>
        B. If the lessee/borrower is an at-fault party or there are no litigants, the lessee/borrower shall be liable
        for the damages (“excess”) at a rate between 1,000-50,000 Baht,
        <b>
          {' '}
          once it has been determined that the damages are within the limits covered by the insurance company. The
          excess rates according to type of car are as follows:
        </b>
      </>,
      <>
        In terms of the rate of excess, the decision of the lessor/lender shall be deemed final. <br />
        In addition, if the insurance company appraises the price and concludes that the damages exceed the coverage
        limits as specified, the lessee/borrower agrees to compensate for the excess amount not covered by the insurance
        company.
      </>,
      'In the case of other damages, it shall be in accordance with the rate of fines pursuant to the agreement.',
      'The lessee/borrower shall be careful and prevent the rented car from being stolen in any way. Furthermore, the lessee/borrower shall present the actual keys to the lessor/lender only. The lessee/borrower shall be responsible for paying the damages at 20% of the appraised car value or the difference in expenses, including any excess amounts that are not covered by the insurance company, unless in the event that the actual keys and car are stolen altogether, or a final court decision with evidence of reasonable or probable cause that the lessee/borrower is at fault resulting in the car being stolen. In this case, the lessee/borrower shall pay all the expenses at 100% of the appraised car value.',
      'The lessee/borrower agrees that the lessor/lender can perform an inspection or move the car when the lessor/lender deems it appropriate to perform any actions to prevent damages to the lessee/borrower or the rented/borrowed car, without any advance notice.',
      'If any of the equipment installed in the car, including documents in the car, car seat, tires, spare parts, jack, keys and others, are damaged or lost during the rental period, the lessee/borrower shall pay all fines according to the list collected by the lessor/lender according to the criteria and amount of fines determined by the service center of such car and the actual service fees.',
      'In terms of car tires, if the tires leak, the lessee/borrower shall repair and retread the tires for the lessor/lender. If the tires burst, the lessee/borrower shall purchase new tires of the same brand and design for the lessor/lender. In case of such incident, the lessee/borrower shall inform the lender immediately when it occurs.',
      'In the event that the rented/borrowed car is seized or it is fined due to the use of the lessee/borrower, the lessor/lender shall be entitled to demand the lessee/borrower to pay the actual fines or any fees from such incident. If the lessee/borrower fails to pay such fines or fees, the lessor/lender shall be entitled to deduct such amount from the security deposit with immediate effect without obtaining consent, and claim for actual compensation resulting from other damages (if any).',
      <>
        <b>
          Duration of the rental period shall begin from the time the car is received and counted as 1 day after 24
          hours has elapsed therefrom.
        </b>{' '}
        The lessee/borrower shall return the car at the time as agreed at the specified location. In case the car is not
        returned at the time and/or location as specified, the lessee/borrower shall inform the lessor/lender at least 3
        hours before the scheduled time.{' '}
        <b>
          The lessor/lender shall be entitled to deny such extension of renting/borrowing which shall be deemed not to
          be in breach of the agreement. However, in the event that the lessor/lender agrees to extend the time of car
          return, the lessee/borrower agrees to pay additional service fees as follows:
        </b>
      </>,
      'Example: The rent is 1,000 Baht/day and the overtime fee is 100 Baht/hour.',
      'Rental agreement: Receiving the car on 5 January at 10:00 hrs. and returning the car on 5 January at 18:00 hrs. The total rental cost for 1 day is 1,000 Baht. The car can be returned on 6 January by 10:00 hrs. without additional charges.',
      'Rental agreement: Receiving the car on 5 January at 10:00 hrs. and returning the car on 6 January at 12:00 hrs. The total rental fee for 1 day and 2 hours is 1,200 Baht. The lessee/borrower desires to return the car another 2 hours later on 6 January at 14:00 hrs. As a result, the additional charges will be 200 Baht, bringing the total cost including the full rental for 1 day and 4 hours to 1,400 Baht. However, if returning the car 3 hours later on 6 January at 15:00 hrs., the total cost including the full rental for 2 days will be 2,000 Baht.',
      '* Changes to the rental conditions in all cases shall be informed to the lessor/lender for acknowledgement and agreement prior to proceeding.',
      'The lessor/lender shall not be responsible for any property left in the rented car by the lessee/borrower in all respects.',
      'The lessee/borrower shall fill the fuel tank to its full capacity as determined before returning the car; otherwise, the lessor/lender shall be entitled to collect fuel charge or deduct the outstanding fuel charge from the security deposit immediately. If the lessee/borrower does not fill the tank with the type of fuel as agreed on, the lessee/borrower agrees to compensate for all damages incurred.',
      'Under this agreement, the lessor/lender shall return the security deposit to the lessee/borrower in full after the lessee/borrower hands over and returns the car, provided that the lessee/borrower has not breached the agreement and no damages have been incurred. However, if the lessee/borrower is in breach of any of the aforementioned provisions of the agreement, the lessee/borrower agrees that the lessor/lender shall be entitled to seize the security deposit with immediate effect and file lawsuit for compensation of the actual damages. If the lessee/borrower fails to pay the fines in the event the lessor/lender exercises its right to impose fine, the lessor/lender shall be entitled to deduct such fines from the security deposit with immediate effect.',
      'This rental agreement is effective from of the date of renting/borrowing as determined in the agreement only, and includes any agreement extension. If the lessee/borrower fails to return the car as scheduled and fails to immediately inform the lessor/lender, if 3 hours have passed since then or the lessee/borrower is not contacted for any reason whatsoever, the lessor/lender/owner/possessor shall be entitled to file complaint with police officers to press criminal charges for theft by means of deception or fraud immediately. If there are any damages or procedural costs arising from such case, the lessee/borrower agrees to be liable for such matters with no objections.',
      'If the lessee/borrower is in breach of any of the aforementioned provisions of the agreement, the lessee/borrower agrees and allows the lessor/lender to seize the placed security deposit with immediate effect in order to initially relieve the damages. Nevertheless, in the event that the damages exceed the security deposit, the lessor/lender shall be entitled to claim for the damages or bring the case to court in order to claim for the actual damages.',
      'Drivehub is a medium for providing car rental service only, and is not responsible for any damages or actions performed by the lessor/lender and/or the lessee/borrower. If the lessee/borrower is in breach of any of the aforementioned provisions of the agreement and fails to compensate for any damages, Drivehub is not considered an employee, guarantor or joint debtor, or collectively responsible under this agreement. Furthermore, it is understood that if this rental agreement has a guarantor, the guarantor thoroughly acknowledges the conditions of this agreement and shall be responsible for the compensation and damages incurred in all respects.'
    ]
  };

  const tableContent = {
    th: [
      'ประเภทรถ',
      'ตัวอย่าง',
      'ค่าเสียหายส่วนแรกมากที่สุด (บาท)',
      'รถอีโคร์คาร์ และ รถขนาดเล็ก',
      'March / Almera / Yaris / Vios / City / Jazz / Ciaz',
      '30,000',
      'รถขนาดกลาง',
      'Altis / Civic / CHR / HRV / Slyphy / Juke',
      '40,000',
      'รถขนาดใหญ่ และ รถเอสยูวี',
      'Camry / Accord / Fortuner / CRV / Pajero / Teana',
      '50,000',
      'จำนวนชั่วโมงที่ล่าช้า',
      'ค่าปรับ',
      'คืนรถล่าช้ากว่ากำหนดแต่ไม่เกิน 24 ชม. นับจากเวลาที่เช่า/ยืม',
      'ไม่มี',
      '1-4 ชั่วโมงแรกที่เกินจาก 24 ชม.',
      'ตามด้านหน้าของสัญญา',
      'หลังจาก 4 ชั่วโมง',
      'ตามด้านหน้าของสัญญา',
      'อัตราค่าปรับ',
      'สาเหตุ',
      'ค่าปรับ',
      'สูบบุหรี่ในรถยนต์',
      '3,000 บาท',
      'คราบยางมะตอย',
      '500-1,500 บาท',
      'อาเจียน หรือคราบสกปรกทำความสะอาดยาก',
      '1,000-3,000 บาท',
      'กุญแจหาย',
      <>
        3,000-10,000 บาท <br />
        (หรือตามบิล)
      </>,
      <>
        ค่าบริการเปิดรถ <br />
        กรณีที่ลืมกุญแจ/กุญแจหาย
      </>,
      <>
        เริ่มต้น 500 บาท <br />
        (หรือตามระยะทาง)
      </>,
      <>
        ค่าบริการเติมน้ำมัน <br />
        กรณีที่ไม่เติมคืนหรือขับเกินที่กำหนด
      </>,
      '300-3,000 บาท',
      'ค่าบริการลากรถ กรณียางแตก ยางรั่ว หรือ อุบัติเหตุ',
      'เริ่มต้น 2,000 บาท',
      'ค่าบริการ ชำระค่าปรับตามใบสั่ง',
      <>
        300 บาท <br />
        (ไม่รวมค่าปรับตามใบสั่ง)
      </>,
      'คืนรถยนต์ก่อนกำหนด (ต้องแจ้งล่วงหน้า 24 ชม.)',
      <>
        50% <br />
        ของค่าเช่าของจำนวนวันที่เหลือ
      </>,
      'ค่าเสียหายส่วนแรก กรณีเป็นฝ่ายผิด/ไม่มีคู่กรณี',
      'เริ่มต้น 1,000 บาท'
    ],
    en: [
      'Type of Car',
      'Example',
      'Maximum Excess Capped at (Baht)',
      'Eco and Small Cars',
      'March / Almera / Yaris / Vios / City / Jazz / Ciaz',
      '30,000',
      'Medium Cars',
      'Altis / Civic / CHR / HRV / Slyphy / Juke',
      '40,000',
      'Large Cars and SUVs',
      'Camry / Accord / Fortuner / CRV / Pajero / Teana',
      '50,000',
      'Amount of Delayed Hours',
      'Penalty Charges',
      'Return of car later than specified but not exceeding 24 hours from the time of rent/borrow',
      'None',
      'The first 1-4 first hours which exceed 24 hours',
      'Refer to Rental Agreement',
      'After 4 hours have passed',
      'Refer to Rental Agreement',
      'Penalty Rates:',
      'Incident',
      'Estimated Charges',
      'Smoking in the car',
      '3,000 Baht',
      'Asphalt stains',
      '500-1,500 Baht',
      'Vomit or tough stains, difficult and costly to clean',
      '1,000-3,000 Baht',
      'Losts of vehicle keys',
      <>
        3,000-10,000 Baht <br />
        (or accordaning to the bill)
      </>,
      <>
        Car opening fee <br />
        In case of forgetting the keys / losing keys
      </>,
      <>
        Car opening fee <br />
        In case of forgetting the keys / losing keys
      </>,
      <>
        Refueling service fee <br />
        In case of failure to refuel or returned with under stated amount
      </>,
      '300-3,000 Baht',
      'Car towing service fee in case of burst or leaking tires or accident',
      'Starting at 2,000 Baht',
      'Service fee for fine payment according to a traffic ticket',
      <>
        300 Baht <br />
        (not including the fine of the traffic ticket)
      </>,
      'Returning the car before the scheduled time (must inform 24 hours in advance)',
      <>
        50% <br />
        of the rental for the remaining days
      </>,
      'Excess in case of being an at-fault party / no litigants',
      'Starting at 1,000 Baht'
    ]
  };

  const termsLocalOnly = {
    th: [
      <>
        <b>*กรณี</b>เช่ารถกับ
      </>,
      'บริษัทรถเช่าท้องถิ่น'
    ],
    en: [
      <>
        <b>*In the case</b> of renting a car from a
      </>,
      'car rental company'
    ]
  };

  const text = content[prefix];
  const tableText = tableContent[prefix];
  const termsLocalOnlyText = termsLocalOnly[prefix];
  return (
    <div className="terms">
      <div className="mb-2">
        {termsLocalOnlyText[0]}
        <div className="d-inline-block ml-2 mr-2">
          <span className="badge badge-local badge-sm">
            <i className="icon-local" />
            LOCAL
          </span>
        </div>
        <span>{termsLocalOnlyText[1]}</span>
      </div>
      <ol>
        <li>{text[0]}</li>
        <li>{text[1]}</li>
        <li>{text[2]}</li>
        <li>{text[3]}</li>
        <li>{text[4]}</li>
        <li>{text[5]}</li>
        <li>{text[6]}</li>
        <li>
          {text[7]}
          <ol className="pl-4">
            <li>
              {text[8]}
              <p className="mb-3">
                {text[9]}
                <br />
                {text[10]}
              </p>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <th>{tableText[0]}</th>
                    <th className="text-center">{tableText[1]}</th>
                    <th className="text-center">{tableText[2]}</th>
                  </tr>
                  <tr>
                    <td>{tableText[3]}</td>
                    <td className="text-center">{tableText[4]}</td>
                    <td className="text-center">{tableText[5]}</td>
                  </tr>
                  <tr>
                    <td>{tableText[6]}</td>
                    <td className="text-center">{tableText[7]}</td>
                    <td className="text-center">{tableText[8]}</td>
                  </tr>
                  <tr>
                    <td>{tableText[9]}</td>
                    <td className="text-center">{tableText[10]}</td>
                    <td className="text-center">{tableText[11]}</td>
                  </tr>
                </tbody>
              </Table>
              {text[11]}
              <br />
            </li>
            <li>{text[12]}</li>
          </ol>
        </li>
        <li>{text[13]}</li>
        <li>{text[14]}</li>
        <li>{text[15]}</li>
        <li>{text[16]}</li>
        <li>{text[17]}</li>
        <li className="mb-0">
          <p className="mb-4">{text[18]}</p>
          <Table striped bordered>
            <tbody>
              <tr>
                <th>{tableText[12]}</th>
                <th className="text-center">{tableText[13]}</th>
              </tr>
              <tr>
                <td>{tableText[14]}</td>
                <td className="text-center">{tableText[15]}</td>
              </tr>
              <tr>
                <td>{tableText[16]}</td>
                <td className="text-center">{tableText[17]}</td>
              </tr>
              <tr>
                <td>{tableText[18]}</td>
                <td className="text-center">{tableText[19]}</td>
              </tr>
            </tbody>
          </Table>
          {text[19]}
          <ol className="pl-4">
            <li>{text[20]}</li>
            <li>{text[21]}</li>
          </ol>
          {text[22]}
          <br />
          <br />
        </li>
        <li>{text[23]}</li>
        <li>{text[24]}</li>
        <li>{text[25]}</li>
        <li>{text[26]}</li>
        <li>{text[27]}</li>
        <li>{text[28]}</li>
      </ol>
      <h2 className="mb-2">
        <b>{tableText[20]}</b>
      </h2>
      <Table striped bordered>
        <tbody>
          <tr>
            <th className="text-center">{tableText[21]}</th>
            <th className="text-center">{tableText[22]}</th>
          </tr>
          <tr>
            <td>{tableText[23]}</td>
            <td className="text-right">{tableText[24]}</td>
          </tr>
          <tr>
            <td>{tableText[25]}</td>
            <td className="text-right">{tableText[26]}</td>
          </tr>
          <tr>
            <td>{tableText[27]}</td>
            <td className="text-right">{tableText[28]}</td>
          </tr>
          <tr>
            <td>{tableText[29]}</td>
            <td className="text-right">{tableText[30]}</td>
          </tr>
          <tr>
            <td>{tableText[31]}</td>
            <td className="text-right">{tableText[32]}</td>
          </tr>
          <tr>
            <td>{tableText[33]}</td>
            <td className="text-right">{tableText[34]}</td>
          </tr>
          <tr>
            <td>{tableText[35]}</td>
            <td className="text-right">{tableText[36]}</td>
          </tr>
          <tr>
            <td>{tableText[37]}</td>
            <td className="text-right">{tableText[38]}</td>
          </tr>
          <tr>
            <td>{tableText[39]}</td>
            <td className="text-right">{tableText[40]}</td>
          </tr>
          <tr>
            <td>{tableText[41]}</td>
            <td className="text-right">{tableText[42]}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TermSection;
